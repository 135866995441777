import * as THREE from 'three'
import gsap from 'gsap'
import Lenis from '@studio-freight/lenis'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
console.log('HEY, what are you doing here? Get out of my console :p')
/**
 * Loaders
 */
const loadingBarElement = document.querySelector('.loading-StiftMaindiv')
const balkenOben = document.querySelector('.loading-balkenOben')
const balkenUnten = document.querySelector('.loading-balkenUnten')

const loadingManager = new THREE.LoadingManager(
    () =>
    {
        gsap.delayedCall(0.5, ()=>
        {
            gsap.to( overlayMaterial.uniforms.uAlpha,{duration:1.5,value:0})
            
        balkenOben.classList.add('ended')
        balkenUnten.classList.add('ended')
        gsap.delayedCall(1.5, ()=>
        {scene.remove(overlay)})
        })
        
    },
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
       const progressRatio = (itemsLoaded/itemsTotal)*110
       loadingBarElement.style.transform = `translateX(${progressRatio}%)`
    }
)
const gltfLoader = new GLTFLoader(loadingManager)
const textureLoader = new THREE.TextureLoader(loadingManager)

/**
 * Debug
 */

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
const rayOrigin = new THREE.Vector3(- 3, 0, 0)
const rayDirection = new THREE.Vector3(10, 0, 0)
rayDirection.normalize()

raycaster.set(rayOrigin, rayDirection)

/**
 * Mouse
 */
const mouse = new THREE.Vector2()

window.addEventListener('mousemove', (event) => {
    mouse.x = event.clientX / sizes.width * 2 - 1
    mouse.y = - (event.clientY / sizes.height) * 2 + 1
    //console.log(mouse)
})

/**
 * Modal
 */

//Stop videos

function stopAllVideos() {
    const videos = document.querySelectorAll('video');
   console.log(videos)
   videos.forEach(video => {
       if (!video.paused && !video.ended) {
           video.pause();
       }
   });
   const iframes = document.querySelectorAll('iframe')
   iframes.forEach(iframe => {
       if (iframe.src.includes('youtube.com/embed')) {
           // Get the video ID from the YouTube URL
           const videoId = new URL(iframe.src).pathname.split('/').pop()
           // Modify the src to pause the video by appending '?autoplay=0'
           iframe.src = `https://www.youtube.com/embed/${videoId}?autoplay=0`;
       }
   });
}

//smooth scroll 
let lenis
let scrollSensitivity = 0.1;
function enableSmoothScroll() {
    if (!lenis) {
        lenis = new Lenis({infinite:false})
        lenis.on('scroll', (e) => {
        })

        function raf(time) {
            if (lenis) {
                lenis.raf(time)
                requestAnimationFrame(raf)
            }

        }

        requestAnimationFrame(raf)
    }
}
enableSmoothScroll()
function disableSmoothScroll() {
    if (lenis) {
        lenis.destroy()
        lenis = null
    }

}

// Get the modal element
const modal = document.getElementById('myModal');
const modalContent1 = document.getElementById('myModalContent1');
const modalContent2 = document.getElementById('myModalContent2');
const modalContent3 = document.getElementById('myModalContent3');
const modalContent4 = document.getElementById('myModalContent4');
const modalContent5 = document.getElementById('myModalContent5');
const modalContent6 = document.getElementById('myModalContent6');
const modalContent7 = document.getElementById('myModalContent7');
const modalContent8 = document.getElementById('myModalContent8');
modal.style.display = 'none';
const body = document.querySelector('body')
window.addEventListener('click', () => {

    if (project1.position.z > 0 || project2.position.z > 0 || project3.position.z > 0 || project4.position.z > 0 || project5.position.z > 0 || project6.position.z > 0 ||project7.position.z > 0||cv.position.z > 0.1) {

        disableSmoothScroll();
        modal.style.display = 'block';
        if (project1.position.z >= 0) {
            modalContent1.style.display = 'block';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none';
            modalContent7.style.display = 'none'
            modalContent8.style.display = 'none'
        } else if (project2.position.z >= 0) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'block';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none'
            modalContent7.style.display = 'none'
            modalContent8.style.display = 'none'
        } else if (project3.position.z >= 0) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'block';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none'
            modalContent7.style.display = 'none'
            modalContent8.style.display = 'none'
        } else if (project4.position.z >= 0) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'block';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none'
            modalContent7.style.display = 'none'
            modalContent8.style.display = 'none'
        } else if (project5.position.z >= 0) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent6.style.display = 'none';
            modalContent5.style.display = 'block'
            modalContent7.style.display = 'none'
            modalContent8.style.display = 'none'
        } else if (project6.position.z >= 0) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'block';
            modalContent7.style.display = 'none'
            modalContent8.style.display = 'none'
        } else if (project7.position.z >= 0) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none';
            modalContent7.style.display = 'block'
            modalContent8.style.display = 'none'
        } 
        else if (cv.position.z >= 0.1) {
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none';
            modalContent8.style.display = 'block'
            modalContent7.style.display = 'none'
        } 
    }else if (figurGroup1.position.z >= 1.01) {
            gsap.to(figurGroup1.rotation, {
                duration: .8,
                ease: 'power2.Out',
                y: figurGroup1.rotation.z + Math.PI*2*Math.random()
            }); 
            
            modalContent1.style.display = 'none';
            modalContent2.style.display = 'none';
            modalContent3.style.display = 'none';
            modalContent4.style.display = 'none';
            modalContent5.style.display = 'none';
            modalContent6.style.display = 'none';
            modalContent7.style.display = 'none'
        }



    const closeBtns = document.querySelectorAll('.close');
    function closeModal() {
        modal.style.display = 'none';
        enableSmoothScroll()
        stopAllVideos()
    }
    closeBtns.forEach(closeBtn => {
        closeBtn.addEventListener('click', closeModal);
    })

    // When the user clicks on <span> (x), close the modal
    window.addEventListener('click', (event) => {
        if (event.target === modal) {
            modal.style.display = 'none';
            enableSmoothScroll()
            stopAllVideos()
        }

    })
});

// Scene
const scene = new THREE.Scene()


//loadingscreen
const overlayMaterial = new THREE.ShaderMaterial({
    transparent:true,
    uniforms:
    {
        uAlpha: {value:1}
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
    uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.7, 0, 0, uAlpha);
        }
    `
})
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)









//Fog
const fog = new THREE.Fog('#ffffff', 7.8, 8.2)
scene.fog = fog

//AboutGroup
const mesh1 = new THREE.Group()

//aboutPic
gltfLoader.load(
    '/models/about/about.glb',
    (glb) => {
        glb.scene.scale.set(1.5, 1.5, 1.5);
        mesh1.add(glb.scene)
    }
)
//aboutCvButton
let cv
gltfLoader.load(
    '/models/about/about2.glb',
    (glb) => {
        cv = glb.scene
        cv.scale.set(.5, .5, .5);
        cv.position.set(.9, -1.16, 0)
        mesh1.add(cv)
    }
)



const hitboxMaterial = new THREE.MeshBasicMaterial({
    visible: false
})
//Sizes/Resize

function isMobile(){
    return /Android|iPhone/i.test(navigator.userAgent);
}
isMobile()

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
if(isMobile()===true){
    if(innerWidth<innerHeight)
{
    alert('TURN YOUR PHONE FOR\nBETTER EXPERIENCE\nthen scroll to explore')
}else if(innerWidth>innerHeight)
{
 body.style.overflow = 'visible';
}
}

window.addEventListener('resize', () => {
    
    
    
    if(isMobile()===true){
        if(innerWidth<innerHeight)
    {
        alert('TURN YOUR PHONE FOR\nBETTER EXPERIENCE\nthen scroll to explore')
        body.style.overflow = 'hidden';
    }else if(innerWidth>innerHeight)
    {
     body.style.overflow = 'visible';
    }
    }
    if(isMobile()===false){
        if(innerWidth<innerHeight)
    {
        alert('USE WIDESCREEN FOR BEST EXPERIENCE')
    }
    }
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    //Update object position
    mesh1.position.x = -sizes.width / 1005
})

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 7


/**
 * Objects
 */
//Groups
const scrollGroup = new THREE.Group()
const parallaxGroup = new THREE.Group()
const figurGroup1 = new THREE.Group()
const figurGroup = new THREE.Group()
const USB = new THREE.Group()
const mesh2 = new THREE.Group()
const mesh3 = new THREE.Group()
const cartonGroup = new THREE.Group()
const project1 = new THREE.Group()
const project2 = new THREE.Group()
const project3 = new THREE.Group()
const project4 = new THREE.Group()
const project5 = new THREE.Group()
const project6 = new THREE.Group()
const project7 = new THREE.Group()




//Figur




let mixer1 = null
let mixer2 = null
gltfLoader.load(
    '/models/figur/figur.glb',
    (glb) => {
        mixer1 = new THREE.AnimationMixer(glb.scene)
        const animations = glb.animations

        const flyBase = mixer1.clipAction(glb.animations[0])
        flyBase.play()
        glb.scene.scale.set(6, 6, 6)
        figurGroup1.position.z = 1
        figurGroup1.rotation.set = (0.5,-0.5,0.01)
        figurGroup.add(figurGroup1)
        figurGroup1.add(glb.scene)
        scene.add(figurGroup)



    }
)
gltfLoader.load(
    '/models/figur/usb.glb',
    (glb) => {

        mixer2 = new THREE.AnimationMixer(glb.scene)

        const flyBase = mixer2.clipAction(glb.animations[0])
        flyBase.play()
        glb.scene.position.z = 0
        glb.scene.scale.set(6, 6, 6)
        USB.add(glb.scene)
        figurGroup1.add(USB)

        scene.add(figurGroup)


    }
)
//ProjectModelle

//Turm
gltfLoader.load(
    '/models/projects/project_Turm.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        project1.add(glb.scene)
    }
)
//Sweat
gltfLoader.load(
    '/models/projects/project_Sweat.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        project2.add(glb.scene)
    }
)
//cellGenerator
gltfLoader.load(
    '/models/projects/project_Cell.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        project3.add(glb.scene)
    }
)
//Flower
gltfLoader.load(
    '/models/projects/project_Flower.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        project4.add(glb.scene)
    }
)
//NichtsIstAlles
gltfLoader.load(
    '/models/projects/project_Nichts.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        project5.add(glb.scene)
    }
)
//Werns
gltfLoader.load(
    '/models/projects/project_Werns.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        project6.add(glb.scene)
    }
)
//B67
gltfLoader.load(
    '/models/projects/project_B67.glb',
    (glb) => {
        glb.scene.scale.set(2, 2, 2);
        glb.scene.rotation.z=-.4;
        project7.add(glb.scene)
    }
)

//CartonModelle
let cartonDeckel
gltfLoader.load(
    '/models/carton/carton.glb',
    (glb) => {
        glb.scene.scale.set(.9, .9, .9)
        glb.scene.position.set(0, 0, 0)
        cartonDeckel = glb.scene.children[0].children[0]
        cartonDeckel.rotation.x = -2.5
        cartonGroup.add(glb.scene)
    }
)
let versandmarke
gltfLoader.load(
    '/models/carton/versandmarke.glb',
    (glb) => {
        glb.scene.scale.set(1.5, 1.5, 1.5)
        glb.scene.rotation.set(0, 0, -.2)
        glb.scene.position.set(0, 0, 7)
        versandmarke = glb.scene
        cartonGroup.add(versandmarke)
    }
)
cartonGroup.position.set(0, -5, 1)
cartonGroup.rotation.set(0, .3, 0)



scrollGroup.add(cartonGroup)



const objectsDistance = 4
const mesh0 = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1, 1, 1, 1)
)
//Project1 DER TURM
const projectHitbox1 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project1.add(projectHitbox1)
project1.scale.set(1.5,1.5,1.5)
project1.position.set(-3.2, -4, -1)
project1.rotation.z = 0

//Project2 SWEAT
const projectHitbox2 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project2.add(projectHitbox2)
project2.position.set(-6.2, -.5, -1)
project2.scale.set(.8, .8, .8)
project2.rotation.z = 0

//project 3 CELL GENERATOR
const projectHitbox3 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project3.add(projectHitbox3)
project3.scale.set(.9, .9, .9)
project3.position.set(1.5, -5.2, -1)
project3.rotation.z = 0

//project 4 BLUMENHAUS
const projectHitbox4 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project4.add(projectHitbox4)
project4.scale.set(.9, .9, .9)
project4.position.set(4.5, 3, -1)
project4.rotation.z = -.2

//project 5 NichtsIstAlles
const projectHitbox5 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project5.add(projectHitbox5)
project5.scale.set(.9, .9, .9)
project5.position.set(1, 6.5, -1)
project5.rotation.z = -.4
//project 6 WERNS
const projectHitbox6 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project6.scale.set(1.6,1.6,1.6)
project6.add(projectHitbox6)
project6.position.set(-4.5, 4.6, -1)
project6.rotation.z = -.2
//project 7 b67
const projectHitbox7 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
project7.add(projectHitbox7)
project7.rotation.z = .8
project7.scale.set(1.2,1,1.2)
project7.position.set(5, -2, -1)
//CV
const projectHitbox8 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)
projectHitbox8.position.set(.9, -1.16, 0)
projectHitbox8.scale.set(.2, .1, .1)
mesh1.add(projectHitbox8)
//figur dreher
const projectHitbox9 = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 5),
    hitboxMaterial
)

projectHitbox9.scale.set(.3, .7, .5)
projectHitbox9.rotation.set(-.5, 0, 0)
projectHitbox9.position.set(0, 0.3, 0)

figurGroup1.add(projectHitbox9)



mesh1.position.y = -objectsDistance * 1
mesh1.position.x = -sizes.width / 1150
mesh1.position.z = 0

mesh2.add(project1, project2, project3, project4, project5, project6,project7)
mesh2.scale.set(0, 0, 0)
mesh2.position.set(0, -.03, -1)

mesh3.position.y = -objectsDistance * 3
mesh3.position.x = 0

const sectionMeshes = [mesh0, mesh1, mesh2, mesh3]

// particles
const particleTexture = textureLoader.load('/textures/particles.png')
const particlesMaterial = new THREE.PointsMaterial({
    size: 0.4,
    sizeAttenuation: true,
    fog: false,
    color: '#F73535',

})
particlesMaterial.alphaMap = particleTexture
particlesMaterial.map = particleTexture
particlesMaterial.transparent = true
particlesMaterial.alphaTest = 0.001
const particlesGeometry = new THREE.BufferGeometry()
const count = 50

const positions = new Float32Array(count * 3)

// Multiply by 3 because each position is composed of 3 values (x, y, z)

for (let i = 0; i < count * 3; i++) // Multiply by 3 for same reason
{
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * 4
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values

const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Group
 */
const textGroup = new THREE.Group()
textGroup.add(mesh1, mesh2, mesh3)

parallaxGroup.add(camera)


scrollGroup.add(parallaxGroup, figurGroup, mesh2)
scene.add(scrollGroup, textGroup)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x000000, 0);
renderer.outputColorSpace = THREE.SRGBColorSpace;

//animation functions
function projectAnimation2() {
    gsap.to(
        cartonGroup.position,
        {
            duration: .3,
            ease: 'power2.in',
            x: 0,
            y: -5,
            z: 1.3,

        })
    gsap.to(cartonGroup.rotation
        ,
        {
            duration: .3,
            ease: 'power2.inOut',
            x: 0.5,
            y: 0.1,
            z: 0,

        })

    gsap.to(versandmarke.position,
        {
            duration: .3,
            ease: 'power2.in',
            x: 0,
            y: 0,
            z: 7
        })
}
function afterMesh2() {
    gsap.to(
        figurGroup1.scale,
        {
            duration: 1,
            ease: 'power2.in',
            x: .8,
            y: .8,
            z: .8
        })



    gsap.to(
        figurGroup.position,
        {
            duration: 3,
            ease: 'power2.inOut',
            x: 0,
            z: 0,
            y: -0.4
        })

    gsap.to(
        cartonGroup.position,
        {
            duration: .5,
            ease: 'power2.out',
            x: 0,
            y: -.1,
            z: 1.5,
            onComplete: cartonDeckelAnimation
        })
    gsap.to(
        cartonGroup.rotation,
        {
            duration: 1,
            ease: 'power2.out',
            x: -0.2,
            y: -0.2,
            z: 0,

        })
}
function cartonDeckelAnimation() {
    if (cartonDeckel !== undefined) {
        gsap.to(
            cartonDeckel.rotation,
            {
                duration: .5,
                ease: 'power2.in',
                x: 0,
            })
        gsap.to(
            versandmarke.position,
            {
                duration: .5,
                ease: 'power2.in',
                x: 0,
                y: 0,
                z: .741
            })

    }
}

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0


window.addEventListener('scroll', () => {
    if (lenis) {
        
        scrollY = window.scrollY
        
        const newSection = Math.round(scrollY / sizes.height)

        if (newSection != currentSection) {
            //Backgroundobjects
            currentSection = newSection
            //Page2
            if ((scrollY / sizes.height) > 0.50 && (scrollY / sizes.height) < 1.50) {
                gsap.to(
                    mesh2.scale,
                    {

                        overwrite:true,
                        duration: 1,
                        ease: 'power2.inOut',
                        y: 0,
                        x: 0,
                        z: 0
                    })
                gsap.to(
                    figurGroup.position,
                    {                        
                        overwrite:true,
                        duration: 1.5,
                        ease: 'power2.inOut',
                        x: sizes.width * 0.0010
                    })

                gsap.to(
                    figurGroup1.rotation,
                    {                        
                        overwrite:true,
                        duration: 1.5,
                        ease: 'power2.inOut',
                        y: -0.9,
                        x: .2,
                        z: 0
                    })

                gsap.to(
                    mesh2.position,
                    {                        
                        overwrite:true,
                        duration: 1.5,
                        ease: 'power2.inOut',
                        x: sizes.width * 0.0015,
                        z: -2,
                        y: .4
                    })

                gsap.to(
                    mesh1.scale,
                    {                        
                        overwrite:true,
                        duration: 1.5,
                        ease: 'power2.inOut',
                        x: 1,
                        y: 1,
                        z: 1
                    })
                gsap.to(
                    versandmarke.position,
                    {                        
                        overwrite:true,
                        duration: .5,
                        ease: 'power2.in',
                        x: 0,
                        y: 0,
                        z: 7
                    })
                gsap.to(
                    cartonGroup.position,
                    {                        
                        overwrite:true,
                        duration: .5,
                        ease: 'power2.in',
                        x: 0,
                        y: -5,
                        z: 1.3,

                    })


            }
            //Page4
            else if ((scrollY / sizes.height) > 2.50) {
                
                gsap.to(
                    mesh2.scale,
                    {                        
                        overwrite:true,
                        duration: .3,
                        ease: 'power2.in',
                        y: 0,
                        x: 0,
                        z: 0,
                        onComplete: afterMesh2

                    })
                    gsap.to(
                        figurGroup1.rotation,
                        {                        
                            overwrite:true,
                            duration: .8,
                            ease: 'power2.inOut',
                            x: 0.2,
                            y: -6.4,
                            z: 0,
    
                        })
                        gsap.to(
                            mesh2.position,
                            {                        
                                overwrite:true,
                                duration: .3,
                                ease: 'power2.inOut',
                                
                                z: -2
                            })

            }
            //Page1
            else if ((scrollY / sizes.height) > 0.00 && (scrollY / sizes.height) < 0.50) {
                gsap.to(
                    mesh2.scale,
                    {                        
                        overwrite:true,
                        duration: 1,
                        ease: 'power2.inOut',
                        y: 0,
                        x: 0,
                        z: 0
                    })
                gsap.to(
                    mesh2.position,
                    {                        
                        overwrite:true,
                        duration: 1,
                        ease: 'power2.inOut',
                        x: 0,
                        z: -2
                    })
                gsap.to(
                    figurGroup.position,
                    {                        
                        overwrite:true,
                        duration: 1,
                        ease: 'power2.inOut',
                        x: 0
                    })
                gsap.to(
                    figurGroup1.rotation,
                    {                        
                        overwrite:true,
                        duration: 1,
                        ease: 'power2.inOut',
                        y: -0.5,
                        x: 0.5,
                        z: 0
                    })

            }


            //Page3
            else if ((scrollY / sizes.height) > 1.50 && (scrollY / sizes.height) < 2.50) {


                if (cartonDeckel !== undefined) {
                    gsap.to(
                        cartonDeckel.rotation,
                        {                        
                            overwrite:true,
                            duration: .5,
                            ease: 'power2.in',

                            x: -2.5,

                            onComplete: projectAnimation2
                        })
                }
                gsap.to(
                    mesh2.scale,
                    {                        
                        overwrite:true,
                        duration: .8,
                        ease: 'power2.inOut',
                        y: .3,
                        x: .3,
                        z: .3
                    })

                gsap.to(
                    mesh2.position,
                    {                        
                        overwrite:true,
                        duration: .8,
                        ease: 'power2.inOut',
                        x: 0,
                        z: -1,
                        y:0
                    })
                gsap.to(
                    figurGroup.position,
                    {                        
                        overwrite:true,
                        duration: .8,
                        ease: 'power2.inOut',
                        x: 0,
                        z: 0,
                        y: 0,
                    })

                gsap.to(
                    figurGroup1.rotation,
                    {                        
                        overwrite:true,
                        duration: .8,
                        ease: 'power2.inOut',
                        x: 0.2,
                        y: -6.4,
                        z: 0,

                    })
                gsap.to(
                    figurGroup1.scale,
                    {                        
                        overwrite:true,
                        duration: .8,
                        ease: 'power2.inOut',
                        x: 1,
                        y: 1,
                        z: 1
                    })

                gsap.to(
                    mesh1.scale,
                    {                        
                        overwrite:true,
                        duration: .8,
                        ease: 'power2.inOut',
                        x: 0,
                        y: 0,
                        z: 0
                    })

            }




        }
    }




});


/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0


const tick = () => {

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime



    //Scroll Camera
    scrollGroup.position.y = (- scrollY / sizes.height * objectsDistance)
    figurGroup.position.y = ((- scrollY / sizes.height * objectsDistance)-0.1)
    //update mixer
    if (mixer1 !== null && mixer2 !== null) {

        if (mixer1) mixer1.update(deltaTime);
        if (mixer2) mixer2.update(deltaTime);
    }


    //Animate Camera
    const parallaxX = cursor.x * 0.5
    const parallaxY = -cursor.y * 0.5
    parallaxGroup.position.x += (parallaxX - parallaxGroup.position.x) * 2 * deltaTime
    parallaxGroup.position.y += (parallaxY - parallaxGroup.position.y) * 2 * deltaTime


    //Raycaster
    raycaster.setFromCamera(mouse, camera)

    const objectsToTest = [projectHitbox1, projectHitbox2, projectHitbox3, projectHitbox4, projectHitbox5, projectHitbox6, projectHitbox7, projectHitbox8, projectHitbox9]
    const intersects = raycaster.intersectObjects(objectsToTest)


    function projectMoveBack() {
        if (project1.position.z >= 0 || project2.position.z >= 0 || project3.position.z >= 0 || project4.position.z >= 0 || project5.position.z >= 0 || project6.position.z >= 0 ||project7.position.z >= 0) {
//turm
            gsap.to(
                project1.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: -3.2,
                    y: -4,
                    z: -1  
                })
                //sweat
            gsap.to(
                project2.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: -6.2,
                    y: -.5,
                    z: -1
                })
                //cell
            gsap.to(
                project3.position,
                {
                    duration: .3,
                    ease: 'power2.Out',

                    x: 1.5, 
                    y: -5.2,
                    z: -1
                })
                //flower
            gsap.to(
                project4.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x:  4.5,
                    y: 3.5,
                    z: -1 
                })
                //NichtsIstAlles
            gsap.to(
                project5.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: 1,
                    y: 6,
                    z: -1
                      
                })
                //werns
            gsap.to(
                project6.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: -4.5,
                    y: 4.6,
                    z: -1
                })
                gsap.to(
                    project7.position,
                    {
                        duration: .3,
                        ease: 'power2.Out',
                        x: 5,
                        y:-2,
                        z: -1

                    })

        }
        if (cv) {
            gsap.to(
                cv.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: .93,
                    y: -1.23,
                    z: 0
                })
        }
        if (figurGroup1) {
            gsap.to(
                figurGroup1.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    z: 1
                })
        }

    }

    if (intersects.length > 0 && modal.style.display == 'none') {
        const intersectedObject = intersects[0].object

        if (intersectedObject === projectHitbox1) {
            //DerTurm
            gsap.to(
                project1.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: -2.9,
                    y: -3.7,
                    z: 2

                })
        }
        //Sweat
        else if (intersectedObject === projectHitbox2) {
            gsap.to(
                project2.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: -5.55,
                    y: -.4,
                    z: 2
                })
        }
        //cellGenerator
        else if (intersectedObject === projectHitbox3) {
            gsap.to(
                project3.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: 1.4, 
                    y: -4.8,
                    z: 2
                })
        }
        //Flower
        else if (intersectedObject === projectHitbox4) {
            gsap.to(
                project4.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x:  4.1,
                    y: 3.1,
                    z: 2
                })
        }
        //NichtsIstAlles
        else if (intersectedObject === projectHitbox5) {
            gsap.to(
                project5.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: 0.95,
                    y: 5.45,
                    z: 2
                })
        }
        //Werns
        else if (intersectedObject === projectHitbox6) {
            gsap.to(
                project6.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: -4.1,
                    y: 4.3,
                    z: 2
                })

        }
        //b67
        else if (intersectedObject === projectHitbox7) {
            gsap.to(
                project7.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: 4.5,
                    y:-1.9,
                    z: 2
                })

        }
        //CV
        else if (intersectedObject === projectHitbox8) {
            gsap.to(
                cv.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    x: .94,
                    y: -1.2,
                    z: 0.2
                })
        }
        //figur
        else if (intersectedObject === projectHitbox9) {
            gsap.to(
                figurGroup1.position,
                {
                    duration: .3,
                    ease: 'power2.Out',
                    z: 1.1
                })
        }

    }

    else if (intersects.length === 0) {
        projectMoveBack()
    }


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()